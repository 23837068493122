import React, {useEffect , useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator , View , Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from './family.png';
import { Helmet } from 'react-helmet';
import { getCurrentUser , fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import CameraDashboard from './components/cameraDashboard';

async function currentdAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
  } catch (err) {
    console.log(err);
  }
}

// Dashboard Component
function Dashboard({ signOut }) {
  const [userAttributes, setUserAttributes] = useState(null);
  const [idToken, setIdToken] = useState(null); 
  const [accessToken, setAccessToken] = useState(null);
  // Fetch user attributes
  useEffect(() => { 

    const fetchUserData = async () => {
      try {
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes(user);
        console.log('Fetched User Attributes:', attributes);
        setUserAttributes(attributes);
        // const idToken = user.signInUserSession.idToken.jwtToken;
        const { accessToken , idToken } = (await fetchAuthSession()).tokens ?? {};
        console.log(`idToken is ${idToken}`);
        console.log(`accessToken is ${accessToken}`);
        setAccessToken(accessToken);
        setIdToken(idToken);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
    fetchUserData();
  }, []);

  if (!userAttributes) {
    return (<div><p>Loading user data...</p>
      <button onClick={signOut}>Sign Out</button></div>
    );
  }

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>
        Welcome, {userAttributes.given_name || 'User'} {userAttributes.family_name || ''}!
      </h1>
      <p>You are logged in as {userAttributes.email || 'unknown email'}</p>
      <button onClick={signOut} style={{ padding: '10px 20px', marginTop: '20px' }}>
        Sign Out
      </button>
      <CameraDashboard idToken={idToken}/>
    </div>
  );
}


const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  height: '100vh',
}

function App() {
  return (
    <div>
      <Helmet>
        <title>Foohouse</title>
      </Helmet>
    <div style ={containerStyle}>
      <Router>
    <Authenticator 
    hideSignUp={true}
    signUpAttributes={['email','given_name','family_name','gender']}
    components={{
      SignIn: {
      Header: () => (
        <View textAlign="center" padding="1px" marginTop="50px">
          <Image
          src = {logo} 
          alt = "Logo"
          style = {{ maxWidth: '150px' }}
          />
          <h2 style={{ marginTop: '10px', color: '#333' }}>
                    Welcome to Foohouse!
                  </h2>
        </View>
        
      )},      SignUp: {
        Header: () => (
          <View textAlign="center" padding="1px">
            <Image
            src = {logo} 
            alt = "Logo"
            style = {{ maxWidth: '150px' }}
            />
            <h2 style={{ marginTop: '10px', color: '#333' }}>
                    Join Foohouse today!
                  </h2>
          </View>
          
        )}
      
    }}>

{({ signOut, user }) => (
            <Routes>
              {/* Redirect authenticated users to dashboard */}
              <Route
                path="/"
                element={<Navigate to="/dashboard" replace />}
              />
              {/* Dashboard as landing page after authentication */}
              <Route
                path="/dashboard"
                element={<Dashboard user={user} signOut={signOut} />}
              />
              {/* Fallback or home page */}
              <Route path="/home" element={<Dashboard user={user} signOut={signOut} />} />
            </Routes>
          )}
      {/* {({ signOut, user }) => (
        <main>
          <h1> Hello {user.userId} </h1>
          <button onClick={signOut}>Sign out</button>
        </main>
      )} */}
    </Authenticator></Router></div></div>
  );
}

// export default withAuthenticator(App,{
//   signUpAttributes:['email'],
//   hideSignUp: false,
// });

// export default withAuthenticator(App);
export default App;
