import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"; 
import { BsEye, BsEyeSlash } from "react-icons/bs"; // Import eye icons


const CameraForm = ({ camera, onClose, onSubmit, API_URL, idToken }) => {
  const [showPassword, setShowPassword] = useState(false); // State for showing/hiding password
  const [cameraName, setCameraName] = useState(camera?.CameraName || "");
  const [ipAddress, setIpAddress] = useState(camera?.IpAddress || "");
  const [username, setUsername] = useState(camera?.Username || "");
  const [password, setPassword] = useState(camera?.Password || "");
  const [location, setLocation] = useState(camera?.Tags?.Location || "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      type: camera ? "update" : "add",
      CameraName: cameraName,
      IpAddress: ipAddress,
      Tags: { Location: location },
    };

    if (camera) {
      payload.CameraId = camera.CameraId;
      if (username !== null) {  // Changed to check against null
        payload.Username = username;
      }
      if (password !== null) {  // Changed to check against null
        payload.Password = password;
      }
    }
    if (!camera) {
      payload.Username = username;
      payload.Password = password;
    }

    try {
      await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });
      console.log(payload);
      onSubmit();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <h3>{camera ? "Edit Camera" : "Add Camera"}</h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={20}>
            <Form.Group controlId="formCameraName">
              <Form.Label>Camera Name:</Form.Label>
              <Form.Control
                type="text"
                value={cameraName}
                onChange={(e) => setCameraName(e.target.value)}
                required={!camera}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={20}>
            <Form.Group controlId="formIpAddress">
              <Form.Label>IP Address:</Form.Label>
              <Form.Control
                type="text"
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
                required={!camera}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={20}>
            <Form.Group controlId="formUsername">
              <Form.Label>Username:</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}   

                required={!camera}
              />
            </Form.Group>   

          </Col>
        </Row>
        <Row>
          <Col xs={20}>
            <Form.Group controlId="formPassword">
              <Form.Label>Password:</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={!camera}

              />
              <span style={{ marginLeft: "10px", cursor: "pointer" }} 
                onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <BsEyeSlash /> : <BsEye />}
              </span>
              </div>
            </Form.Group>   

          </Col>
        </Row>
        <Row>
          <Col xs={20}>
            <Form.Group controlId="formLocation">
              <Form.Label>Location:</Form.Label>
              <Form.Control
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}   

                required={!camera}
              />
            </Form.Group>   

          </Col>
        </Row>

        <Row>
          <Col>
            <Button variant="primary" type="submit">
              {camera ? "Update" : "Add"}
            </Button>{" "}
            <Button variant="secondary" type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CameraForm;