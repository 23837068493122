import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import CameraForm from "./CameraForm";

const API_URL = "https://u5tx7hitwj.execute-api.ap-southeast-1.amazonaws.com/prod/cameras/"

function CameraDashboard({idToken}){
  const [cameras, setCameras] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentCamera, setCurrentCamera] = useState(null);
  

  // Fetch camera data from API
  useEffect(() => {
    fetchCameras();
  },[]);

  const fetchCameras = async () => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ type: "list", ExcludeState: "DELETED" }),
      });
      const data = await response.json();
      setCameras(data);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  };

  const handleAdd = () => {
    setCurrentCamera(null); // Clear current camera for new addition
    setIsFormOpen(true);
  };

  const handleEdit = (camera) => {
    setCurrentCamera(camera); // Pass camera details to the form
    setIsFormOpen(true);
  };

  const handleDelete = async (cameraId) => {
    try {
      await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ type: "delete", CameraId: cameraId }),
      });
      fetchCameras(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting camera:", error);
    }
  };

  const handleFormSubmit = () => {
    fetchCameras(); // Refresh the list after add/edit
    setIsFormOpen(false);
  };

    // Styles for the table
  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
    };
  const thStyle = {
    border: "1px solid black",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    };
  const tdStyle = {
    border: "1px solid black",
    padding: "8px",
    };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "25%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div>
      <h2>Camera Dashboard</h2>
      <table style={tableStyle}>
        <thead>
          <tr>
          <th style={thStyle}>Camera Name</th>
            <th style={thStyle}>IP Address</th>
            <th style={thStyle}>State</th>
            <th style={thStyle}>Location</th>
            <th style={thStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cameras.map((camera) => (
            <tr key={camera.CameraId}>
              <td style={tdStyle}>{camera.CameraName}</td>
              <td style={tdStyle}>{camera.IpAddress}</td>
              <td style={tdStyle}>{camera.State}</td>
              <td style={tdStyle}>{camera.Tags.Location}</td>
              <td style={tdStyle}>
                <Button variant="primary" onClick={() => handleEdit(camera)}>Edit</Button>{" "}
                <Button variant="danger" onClick={() => handleDelete(camera.CameraId)}>Delete</Button>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ marginTop: "20px" }}>
      <Button variant="success" onClick={handleAdd}>Add Camera</Button>
      </div>
      <Modal isOpen={isFormOpen} onRequestClose={()=>  setIsFormOpen(false)} style={modalStyles}>
        <CameraForm idToken={idToken}
          camera={currentCamera}
          onClose={() => setIsFormOpen(false)}
          onSubmit={handleFormSubmit}
          API_URL={API_URL}
        />
      </Modal>
    </div>
  );
};

export default CameraDashboard;
